var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.teams,
      loading: _vm.teamsLoading,
      "row-key": function (record) {
        return record.id
      },
      pagination: _vm.pagination,
    },
    on: { change: _vm.handleTableChange },
    scopedSlots: _vm._u([
      {
        key: "operations",
        fn: function (text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-right" },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$router.push("/admin/member-listing/" + row.id)
                    },
                  },
                },
                [_vm._v("\n      View Memeber\n    ")]
              ),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }