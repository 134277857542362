var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    { attrs: { padding: "" } },
    [
      _c("club-info-header", { attrs: { member: _vm.member } }),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { xl: 6, lg: 12, md: 12, sm: 24, xs: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-widget",
                  attrs: { title: "Club admin" },
                },
                [
                  _c("ClubInfoCard", { attrs: { member: _vm.member } }),
                  _vm.pageLoading
                    ? _c(
                        "p",
                        { staticStyle: { "text-align": "center" } },
                        [_c("a-spin")],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xl: 9, lg: 12, md: 12, sm: 24, xs: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-widget",
                  attrs: { title: "Club Team" },
                },
                [_c("club-teams-table")],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xl: 9, lg: 12, md: 12, sm: 24, xs: 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-widget",
                  attrs: { title: "Club Coach" },
                },
                [_c("club-teams-table")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }