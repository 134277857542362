var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.member
    ? _c(
        "div",
        {
          staticClass:
            "teams-box teams-box-custom primary-gradient-bg member-header",
        },
        [
          _c(
            "div",
            { staticClass: "gx-profile-banner gx-blue-cyan-gradient" },
            [
              _c("div", { staticClass: "gx-profile-container" }, [
                _c("div", { staticClass: "gx-profile-banner-top" }, [
                  _c("div", { staticClass: "gx-profile-banner-top-left" }, [
                    _c(
                      "div",
                      { staticClass: "gx-profile-banner-avatar-info" },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.member.club_name) +
                                "\n            "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "gx-mb-0 gx-fs-lg member-header--has-payment",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/custom-icons/shield.svg"),
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(_vm.member.email))]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "gx-profile-banner-top-right" }),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }